import React from "react";

import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { PolyLinePath, Shape } from "js/core/utilities/shapes";

import { SVGElement } from "../../base/SVGElement";
import { connectorBoldValues } from "../connectors/ConnectorItem";

export class AuthoringPathElement extends SVGElement {
    static get schema() {
        const path = new PolyLinePath();
        path.addPoint(0, 0);
        path.addPoint(0, 0);
        return {
            fill: "none",
            stroke: "black",
            strokeStyle: "solid",
            strokeWidth: 2,
            points: path.points.map(point => point.toObject())
        };
    }

    get preserveAspectRatio() {
        return true;
    }

    get allowDecorationStyles() {
        return false;
    }

    get allowEditing() {
        return true;
    }

    get canRefreshElement() {
        return true;
    }

    get DOMNode() {
        // ref seems to get lost when exporting to PPT
        // this allows getting access
        return this.parentElement.DOMNode.querySelector("svg");
    }

    setupElement() {
        this.svgRef = React.createRef();
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition, true);
    }

    onResize(boundsBeforeResize) {
        for (const point of this.model.points) {
            point.x *= this.model.width / boundsBeforeResize.width;
            point.y *= this.model.height / boundsBeforeResize.height;
        }
    }

    _calcProps(props, options) {
        const { size } = props;
        const { fill, stroke, strokeWidth, strokeStyle } = this.model;

        this.styles.fill = fill;
        this.styles.stroke = stroke;

        if (this.styles.fill && !this.canvas.getTheme().palette.isRawColor(this.styles.fill)) {
            this.styles.fill = this.canvas.getTheme().palette.getColor(this.styles.fill).toRgbString();
        }

        if (this.styles.stroke && !this.canvas.getTheme().palette.isRawColor(this.styles.stroke)) {
            this.styles.stroke = this.canvas.getTheme().palette.getColor(this.styles.stroke).toRgbString();
        }

        this.styles.strokeWidth = strokeWidth;

        switch (strokeStyle) {
            case "dashed":
                this.styles.strokeDash = `${strokeWidth * 6}px ${strokeWidth * 4}px`;
                break;
            case "dotted":
                this.styles.strokeDash = `${strokeWidth}px ${Math.max(strokeWidth, 4)}px`;
                break;
            default:
                this.styles.strokeDash = null;
        }

        const path = this.model.points.map(({ x, y }) => [x, y]);

        return { size, path };
    }

    renderSVG(props, styles, transition) {
        const { bounds } = props;

        const path = new PolyLinePath();
        path.points = this.model.points.map(({ x, y }) => new geom.Point(x, y));

        let growProgress = 1;
        if (this.isAnimating && "growProgress" in this.animationState) {
            growProgress = this.animationState.growProgress;
            path.trimLength(path.length * growProgress);
        }

        const decorationPaths = [];
        if (styles.stroke && styles.strokeWidth && styles.strokeWidth != 0) {
            const decorationStyles = {
                ...styles,
                fill: styles.stroke,
                strokeDash: null,
                strokeDasharray: null
            };

            let {
                strokeWidth,
                arrowOffset,
                arrowLength,
                arrowWidth,
                circleRadius,
            } = connectorBoldValues;

            if (decorationStyles.strokeWidth !== "bold") {
                strokeWidth = parseInt(decorationStyles.strokeWidth);
                arrowOffset = this.model.arrowHeads?.offset || 0;
                arrowLength = (this.model.arrowHeads?.arrowLength || 10) + strokeWidth * 2;
                arrowWidth = (this.model.arrowHeads?.arrowLength || 10) + strokeWidth * 2;
                circleRadius = 2 + strokeWidth;
            }

            let oldArrowLenth = arrowLength;
            if (["arrow", "circle"].includes(this.model.startDecoration) && ["arrow", "circle"].includes(this.model.endDecoration)) {
                circleRadius = Math.min(path.length / 4, circleRadius);
                arrowLength = Math.min(path.length / 4, arrowLength);
            } else if ([this.model.startDecoration, this.model.endDecoration].includes("arrow")) {
                arrowLength = Math.min(path.length / 2, arrowLength);
            } else if ([this.model.startDecoration, this.model.endDecoration].includes("circle")) {
                circleRadius = Math.min(path.length / 2, circleRadius);
            }
            arrowWidth = arrowLength / oldArrowLenth * arrowWidth;

            if (growProgress > 0 && this.model.startDecoration === "arrow") {
                path.trimStart(arrowLength);
                const startDecorationPathData = Shape.drawArrowHead(path.getSegment(0), false, {
                    arrowLength,
                    arrowOffset,
                    arrowWidth,
                });
                decorationPaths.push(<path key="start-decoration" d={startDecorationPathData}
                    style={decorationStyles} />);
            } else if (growProgress > 0 && this.model.startDecoration === "circle") {
                path.trimStart(circleRadius);
                const startDecorationPathData = Shape.drawCircle(circleRadius, path.points[0]).toPathData();
                decorationPaths.push(<path key="start-decoration" d={startDecorationPathData}
                    style={decorationStyles} />);
            }

            if (growProgress === 1 && this.model.endDecoration === "arrow") {
                path.trimEnd(arrowLength);
                const endDecorationPathData = Shape.drawArrowHead(path.getSegment(path.segments - 1), true, {
                    arrowLength,
                    arrowOffset,
                    arrowWidth,
                });
                decorationPaths.push(<path key="end-decoration" d={endDecorationPathData} style={decorationStyles} />);
            } else if (growProgress === 1 && this.model.endDecoration === "circle") {
                path.trimEnd(circleRadius);
                const endDecorationPathData = Shape.drawCircle(circleRadius, path.points[path.points.length - 1]).toPathData();
                decorationPaths.push(<path key="end-decoration" d={endDecorationPathData} style={decorationStyles} />);
            }
        }

        return (
            <SVGGroup key={this.id} ref={this.svgRef}>
                <svg
                    width={bounds.width}
                    height={bounds.height}
                    style={{
                        overflow: "visible",
                        position: "absolute",
                        left: "0",
                        top: "0"
                    }}
                >
                    <path d={path.toPathData()} style={styles} />
                    {decorationPaths}
                </svg>
            </SVGGroup>
        );
    }

    containsPoint(pt) {
        let svg = this.svgRef.current.ref.current;

        let svgPoint = svg.createSVGPoint();
        svgPoint.x = pt.x - this.canvasBounds.left;
        svgPoint.y = pt.y - this.canvasBounds.top;

        let isClosedPath = this.calculatedProps.path[0][0] == this.calculatedProps.path[this.calculatedProps.path.length - 1][0] && this.calculatedProps.path[0][1] == this.calculatedProps.path[this.calculatedProps.path.length - 1][1];

        let shape = svg.firstElementChild.firstElementChild;
        let style = shape.getAttribute("style");
        shape.setAttribute("style", style.replace(/stroke-width: .*?;/, "stroke-width: 10px;"));
        let isInStroke = shape.isPointInStroke(svgPoint);
        let isInFill = (this.styles.fill != "none" || isClosedPath) && shape.isPointInFill(svgPoint);
        shape.setAttribute("style", style);
        return isInFill || isInStroke;
    }

    get animationElementName() {
        return `Path #${this.parentElement.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => this.animationState.growProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                return this;
            }
        }];
    }
}
