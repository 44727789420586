import * as geom from "js/core/utilities/geom";

import { Video } from "../../elements/Video";
import { BaseElement } from "../../base/BaseElement";
import { BoxFrame, CircleFrame, ImageFrame } from "../../base/Frame";

export class AuthoringVideo extends BaseElement {
    // prevent use override of aspect ratio when resizing using mod key. only makes sense if preserveAspectRatio = true
    get lockAspectRatio() {
        return true;
    }

    get preserveAspectRatioOnCornerResize() {
        return true;
    }

    get showFrame() {
        return this.frameType !== "none";
    }

    get frameType() {
        return this.model.frameType || "none";
    }

    _build() {
        switch (this.frameType) {
            case "light":
            case "dark":
                this.frame = this.addElement("frame", () => BoxFrame, {
                    frameStyle: this.frameType
                });
                break;
            case "circle":
            case "circle-light":
            case "circle-dark":
                this.frame = this.addElement("frame", () => CircleFrame, {
                    frameStyle: this.frameType
                });
                break;
            case "none":
                this.frame = null;
                break;
            default:
                this.frame = this.addElement("frame", () => ImageFrame, {
                    model: {
                        frame: this.frameType
                    }
                });
        }

        this.video = this.addElement("video", () => Video, { noPlaybackArrow: true });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        if (this.showFrame) {
            const frameProps = this.frame.calcProps(size);
            frameProps.bounds = new geom.Rect(0, 0, frameProps.size);
            frameProps.layer = 1;

            const videoProps = this.video.calcProps(frameProps.clipBounds.size, { backgroundColor: "#222" });
            videoProps.bounds = new geom.Rect(0, 0, videoProps.size).offset(frameProps.bounds.left, frameProps.bounds.top).offset(frameProps.clipBounds.left, frameProps.clipBounds.top);

            if (frameProps.frameClipPath) {
                videoProps.clipPath = frameProps.frameClipPath;
            }
            if (frameProps.customClip) {
                videoProps.customClip = frameProps.customClip;
            }

            return { size: frameProps.size };
        } else {
            const videoProps = this.video.calcProps(size);
            videoProps.bounds = new geom.Rect(0, 0, videoProps.size);
            return { size: videoProps.size };
        }
    }
}
